import { theme as smoothTheme, th } from '@smooth-ui/core-sc';

const defaultFontStack = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Open Sans',
  'Helvetica Neue',
  'sans-serif'
].join();

const serifFontStack = ['Times', 'Times New Roman', 'serif'].join();

const fonts = {
  fontPrimary: `Karla, ${defaultFontStack}`,
  fontAlt: `TimesSansSerif, ${serifFontStack}`,
  fontFamily: th('fontPrimary'),
  headingsFontFamily: th('fontAlt'),
  fontSizes: [
    '1.2rem',
    '1.4rem',
    '1.6rem',
    '2.1rem',
    '3.5rem',
    '4.0rem',
    '4.8rem',
    '6.4rem',
    '7.2rem'
  ],

  fontSizeBase: '1rem',

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightBold: 800,

  lineHeightBase: 1.5,
  lineHeightSm: 1.5,
  lineHeightLg: 1.5,

  // Heading Font-sizes
  h1FontSize: '3.5rem',
  h2FontSize: '3.5rem'
};

const borderRadii = {
  borderRadius: '.25rem',
  borderRadiusSm: '.5rem',
  borderRadiusLg: '.8rem'
};

const colors = {
  white: 'hsla(0, 0%, 100%, 1)',
  darkgrey: 'hsla(210, 3%, 13%, 1)',
  primary: th('teal'),
  green: 'rgba(212, 227, 214, 1)',
  green20: 'rgba(212, 227, 214, 0.2)',
  teal: 'rgba(0, 113, 107, 1)',
  teal20: 'rgba(0, 113, 107, 0.2)',
  teal40: 'rgba(60, 144, 138, 1)',
  teal90: 'rgba(219, 240, 239, 1)',
  red: 'rgba(252, 0, 0, 1)',
  orange: 'rgba(255, 217, 185, 1)',
  brick: 'rgba(241, 180, 128, 1)'
};

const zIndexes = {
  zMobileMenu: 100,
  zHeader: 200,
  zNotification: 300
};

export const easings = {
  easeInOutBack: [0.68, -0.55, 0.265, 1.55],
  easeInOutCirc: [0.785, 0.135, 0.15, 0.86],
  easeInOutExpo: [1.0, 0.0, 0.0, 1.0],
  easeInOutSine: [0.445, 0.05, 0.55, 0.95],
  easeInOutQuint: [0.86, 0.0, 0.07, 1.0],
  easeInOutQuart: [0.77, 0.0, 0.175, 1.0],
  easeInOutCubic: [0.645, 0.045, 0.355, 1.0],
  easeInOutQuad: [0.455, 0.03, 0.515, 0.955],
  easeOutBack: [0.175, 0.885, 0.32, 1.275],
  easeOutCirc: [0.075, 0.82, 0.165, 1.0],
  easeOutExpo: [0.19, 1.0, 0.22, 1.0],
  easeOutSine: [0.39, 0.575, 0.565, 1.0],
  easeOutQuint: [0.23, 1.0, 0.32, 1.0],
  easeOutQuart: [0.165, 0.84, 0.44, 1.0],
  easeOutCubic: [0.215, 0.61, 0.355, 1.0],
  easeOutQuad: [0.25, 0.46, 0.45, 0.94],
  easeInBack: [0.6, -0.28, 0.735, 0.045],
  easeInCirc: [0.6, 0.04, 0.98, 0.335],
  easeInExpo: [0.95, 0.05, 0.795, 0.035],
  easeInSine: [0.47, 0.0, 0.745, 0.715],
  easeInQuint: [0.755, 0.05, 0.855, 0.06],
  easeInQuart: [0.895, 0.03, 0.685, 0.22],
  easeInCubic: [0.55, 0.055, 0.675, 0.19],
  easeInQuad: [0.55, 0.085, 0.68, 0.53]
};

const easingsValues = Object.values(easings);
const easingsKeys = Object.keys(easings);
const cssEasing = {};

easingsValues.map((ease, index) => {
  return (cssEasing[easingsKeys[index]] = `cubic-bezier(${ease})`);
});

// Spaces
const unit = 8;
const amount = 32;
const spaces = Array.from(Array(amount), (e, i) => i * unit);

/**
 * ! Breakoints
 */
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1140,
  xl: 1300
};

const gridMaxWidths = {
  sm: 1180,
  md: 1180,
  lg: 1180,
  xl: 1180
};

const theme = {
  ...smoothTheme,
  ...fonts,
  ...colors,
  ...zIndexes,
  ...cssEasing,
  ...borderRadii,
  spaces,
  breakpoints,
  gridMaxWidths,

  // Grid
  gridGutter: '2rem'
};

export default theme;
