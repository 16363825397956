/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'core-js/modules/es6.set';
import 'core-js/modules/es6.map';
import 'raf/polyfill';

import React from 'react';
import AppProvider from './src/store/Provider';

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;
