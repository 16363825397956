import React from 'react';

export const defaultUiContext = {
  isIE: false,
  setIsIE: () => {},
  cartIsOpen: false,
  toggleCart: () => {},
  mobileMenuIsOpen: false,
  toggleMobileMenu: () => {}
};

export default React.createContext(defaultUiContext);
