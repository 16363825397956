// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-property-js": () => import("/opt/build/repo/web/src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/web/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kredieten-js": () => import("/opt/build/repo/web/src/pages/kredieten.js" /* webpackChunkName: "component---src-pages-kredieten-js" */),
  "component---src-pages-over-ons-js": () => import("/opt/build/repo/web/src/pages/over-ons.js" /* webpackChunkName: "component---src-pages-over-ons-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/web/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-vastgoed-js": () => import("/opt/build/repo/web/src/pages/vastgoed.js" /* webpackChunkName: "component---src-pages-vastgoed-js" */),
  "component---src-pages-verzekeringen-js": () => import("/opt/build/repo/web/src/pages/verzekeringen.js" /* webpackChunkName: "component---src-pages-verzekeringen-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/web/.cache/data.json")

