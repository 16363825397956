import React, { Component } from 'react';

import { ThemeProvider } from '@smooth-ui/core-sc';

import UiContext, { defaultUiContext } from '../context/UiContext';
import theme from '../utils/theme';

export class Provider extends Component {
  state = {
    ui: {
      ...defaultUiContext,
      setIsIE: () => {
        if (typeof window === 'object' && !!document.documentMode) {
          this.setState(state => ({
            ui: {
              ...state.ui,
              isIE: true
            }
          }));
        }
      },
      toggleCart: () => {
        this.setState(state => ({
          ui: {
            ...state.ui,
            cartIsOpen: !state.ui.cartIsOpen
          }
        }));
      },
      toggleMobileMenu: () => {
        if (this.state.ui.mobileMenuIsOpen) {
          document.body.style.overflow = '';
        } else {
          document.body.style.overflow = 'hidden';
        }
        this.setState(state => ({
          ui: {
            ...state.ui,
            mobileMenuIsOpen: !state.ui.mobileMenuIsOpen
          }
        }));
      }
    }
  };

  componentDidMount() {
    this.state.ui.setIsIE();
  }

  render() {
    const { ui } = this.state;
    const { children } = this.props;

    return (
      <UiContext.Provider value={ui}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </UiContext.Provider>
    );
  }
}

export default Provider;
